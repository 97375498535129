export const routes = [
  {
    key: '0',
    path: '/',
    collapsible: false,
    title: 'الرئيسية',
    icon: 'fas fa-home'
  },
  {
    key: '1',
    collapsible: true,
    title: 'قسم الملفات',
    icon: 'fas fa-archive',
    nestedRoutes: [
      {
        path: '/reception',
        title: 'الاستقبال',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/visitReports',
        title: 'تقرير الباحث',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/family',
        title: 'استمارة دراسة حالة أسرة',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/interiorFamily',
        title: 'استمارة دراسة حالة أسر الولايات',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/familyMembers',
        title: 'بيانات افراد الاسرة',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/donation',
        title: 'مساعدات الأسر',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/financialAssistance',
        title: 'مساعدات المالية للأسرة',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/foodStuffAssistance',
        title: 'مساعدات مواد غذائية',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/sponsorship',
        title: 'الكفالات',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/borrow',
        title: 'الاستعارة',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/upload',
        title: 'رفع و تحميل مرفقات الاسرة',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/uploadInteriorFamily',
        title: 'رفع و تحميل مرفقات اسر الولايات',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/uploadSponsor',
        title: 'رفع و تحميل مرفقات الكفالات',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/familyStudyPrintPage',
        title: 'طباعة استمارة الاسرة',
        icon: 'fab fa-envira',
        isExternalLink: true
      },
      {
        path: '/familyMembersPrint',
        title: 'طباعة افراد الاسرة',
        icon: 'fab fa-envira',
        isExternalLink: false
      }
      // {
      //   path: "/archived",
      //   title: 'المؤرشف',
      //   icon: 'fas fa-angle-double-left'
      // }
    ]
  },
  {
    key: '2',
    collapsible: true,
    title: 'قسم الايتام',
    icon: 'fas fa-child',
    nestedRoutes: [
      {
        path: '/orphanSponsors',
        title: 'كفلاء الايتام',
        icon: 'fab fa-envira',
        isExternalLink: false,
        subPaths: [
          { subPath: '/orphanSponsorships/', subPathTitle: 'كفالات الايتام' },
          { subPath: '/orphanPayments/', subPathTitle: 'دفعات الايتام' }
        ]
      },
      {
        path: '/orphanFamily',
        title: 'بيانات اسر الايتام',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/orphans',
        title: 'بيانات الايتام',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/noPaymentSponsors',
        title: 'بيانات الكفالاء بلا دفعات',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/orphanSponsors/home',
        title: 'صفحة الكفلاء العامة',
        icon: 'fab fa-envira',
        isExternalLink: false
      }
      // {
      //   path: '/orphanSponsorships',
      //   title: 'كفالات الايتام',
      //   icon: 'fab fa-envira',
      //   isExternalLink: false
      // }
    ]
  },
  {
    key: '3',
    collapsible: true,
    title: 'قسم الاسر المعسرة',
    icon: 'fas fa-shopping-cart',
    nestedRoutes: [
      // {
      //   path: '/lowIncomeFamilies',
      //   title: 'بيانات الاسر المعسرة',
      //   icon: 'fab fa-envira',
      //   isExternalLink: false
      // },
      {
        path: '/insolventFamilies',
        title: 'بيانات الاسر المعسرة',
        icon: 'fab fa-envira',
        isExternalLink: false,
        subPaths: [
          {
            subPath: '/lowIncomeSponsorships/',
            subPathTitle: 'كفالات الاسر المعسرة'
          },
          {
            subPath: '/lowIncomePayments/',
            subPathTitle: 'دفعات الاسر المعسرة'
          }
        ]
      },
      {
        path: '/lowIncomeSponsors',
        title: 'كفلاء الاسر المعسرة',
        icon: 'fab fa-envira',
        isExternalLink: false
      }
    ]
  },
  {
    key: '4',
    // path: '/productiveFamilies',
    collapsible: true,
    title: 'قسم الاسر المنتجة',
    icon: 'fas fa-briefcase',
    nestedRoutes: [
      {
        path: '/productiveFamilies',
        title: 'بيانات الافراد',
        icon: 'fab fa-envira',
        isExternalLink: false
      },
      {
        path: '/productiveFamilies/notifications',
        title: 'السجلات',
        icon: 'fab fa-envira',
        isExternalLink: false
      }
    ]
  },
  {
    key: '5',
    collapsible: true,
    title: 'مساعدت الاعياد',
    icon: 'fas fa-gift',
    nestedRoutes: [
      {
        path: '/ramadan',
        title: 'قائمة رمضان',
        icon: 'fas fa-moon',
        isExternalLink: false
      },
      {
        path: '/ramadanPrint',
        title: 'طباعة قائمة رمضان',
        icon: 'fas fa-print',
        isExternalLink: false
      },
      {
        path: '/eidAlAdhaDonations',
        title: 'قائمة عيد الاضحى',
        icon: 'fas fa-kaaba',
        isExternalLink: false
      },
      {
        path: '/eidAlAdhaDonationsPrint',
        title: 'طباعة قائمة عيد الاضحى',
        icon: 'fas fa-print',
        isExternalLink: false
      }
    ]
  }
]
