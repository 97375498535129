import React from 'react'
import PropTypes from 'prop-types'

import '../App.css'

// Import React Table
import ReactTable from 'react-table'
import 'react-table/react-table.css'

const Table = ({
  tableData,
  columnData,
  title,
  defaultPageSize = 10,
  loading,
  r
}) => {
  const data = tableData
  let columns = columnData
  return (
    <>
      <h1>{title}</h1>
      <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={defaultPageSize}
        filterable
        loading={loading}
        className="-striped -highlight"
        style={{ backgroundColor: 'white', color: '#292f33', fontSize: '18px' }}
        ref={r}
        noDataText="لا يوجد بيانات"
        defaultFilterMethod={(filter, row) => {
          return filter.value === '!'
            ? row[filter.id] === ''
            : String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
        }}
        getTheadGroupProps={() => {
          return {
            style: {
              background: 'linear-gradient(to bottom, #16a085, #50c9ac)', //'#16a085',#19b394
              color: 'white'
            }
          }
        }}
        getTheadProps={() => {
          return {
            style: {
              background: 'linear-gradient(to top, #110c11, #616161)', //'#110c11',
              color: 'white'
            }
          }
        }}
        getTheadThProps={() => {
          return {
            style: {
              border: '0.5px solid #e0dfdf'
            }
          }
        }}
        getTdProps={() => {
          return {
            style: {
              border: '0.1px solid #110c11'
            }
          }
        }}
      />
      <div>* استعمل علامة "!" للبحث عن عمود بلا بيانات</div>
    </>
  )
}

Table.propTypes = {
  tableData: PropTypes.array,
  columnData: PropTypes.array,
  title: PropTypes.string,
  defaultPageSize: PropTypes.number,
  loading: PropTypes.bool,
  r: PropTypes.any
}

export default Table
