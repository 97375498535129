import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Panel } from 'react-bootstrap'

// import toastrBanner from '../../../components/Message'
// import message from '../../../data/toastrMessages'

import logo from '../../../images/logo-optimized.png'

import '../../login.css'

const OrphanSponsorsHome = ({history}) => {
    const [orphanSponsorPhoneNumber, setOrphanSponsorPhoneNumber] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        history.push(`/orphanSponsors/sponsoredOrphanList?phoneNumber=${orphanSponsorPhoneNumber}`)
    }
    return (
        <>
        <div className="login-page-container">
          <div className="login-page-form-wrapper">
            <Panel className="box pad">
              <div className="image-wrapper">
                <img src={logo} alt="logo" width="100px" height="100px" />
              </div>
              <h1>بوابة كفلاء الايتام</h1>
              <br />
  
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="orphanSponsorCivilId">رقم الهاتف :</label>
                  <input
                    type="number"
                    className="form-control"
                    id="orphanSponsorPhoneNumber"
                    placeholder="رقم الهاتف"
                    value={orphanSponsorPhoneNumber}
                    name="orphanSponsorPhoneNumber"
                    onChange={e => setOrphanSponsorPhoneNumber(e.target.value)}
                    required
                  />
                </div>

                <button type="submit" className="btn btn-default submit-button">
                  ادخال
                </button>
              </form>
            </Panel>
          </div>
        </div>
      </>
    )
}

OrphanSponsorsHome.propTypes = {
    history: PropTypes.object
  }

export default OrphanSponsorsHome