export default {
  dataFetchedSuccessfully: 'تم تحميل البينات بنجاح من قاعدة المعلومات',
  dataFetchingFailed: 'فشل في جلب البيانات من قاعدة المعلومات',
  dataReadyForEditing: 'البيانات جاهزة للتحديث',
  dataEditingFailed: 'فشل في تحديث البيانات. قد يكون المستخدم قارئ فقط',
  dataEditedSuccessfully: 'تم تعديل البينات بنجاح!',
  dataPostedSuccessfully: 'تم ادخال البينات بنجاح!',
  dataPostingFailed:
    'فشل في ادخال البيانات الى قاعدة المعلومات. قد يكون المستخدم قارئ فقط',
  dataDeletedSuccessfully: 'تم مسح البينات بنجاح!',
  dataDeletingFailed: 'فشل في مسح البيانات. قد يكون المستخدم قارئ فقط',
  duplicateData: 'لا يمكن ادخال رقم ملف اسرة مكرر!',
  userNotLoggedIn: 'يجب تسجيل الدخول',
  wrongUsernameOrPassword: 'اسم المستخدم أو كلمة المرور خاطئة',
  notConnected: 'فشل في الاتصال بقاعدة البيانات',
  selectFirst: 'اختر الاسرة اولا',
  enterDateInCorrectFormat: 'ادخل التاريخ بالطريقة الصحيحة',
  keepFamilyIdInputEmpty: 'الحالة جديدة! يرجى ابقاء رقم الاسرة فارغة'
}
