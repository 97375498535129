import React from 'react'

const Seperator = () => (
  <>
    <br />
    <hr />
    <br />
  </>
)

export default Seperator
